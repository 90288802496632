<template>
  <div>
    <div class="container-fluid pb-3 pt-5 pt-md-6">
      <div class="row col-12 page-head">
        <nav aria-label="breadcrumb" role="navigation">
          <ol class="breadcrumb page-head-nav">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'dashboard' }">Home</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'support-tickets' }"
                >Support Tickets</router-link
              >
            </li>
            <li class="breadcrumb-item">Ticker Details</li>
          </ol>
        </nav>
      </div>
      <div class="row">
        <div class="row col-12 text-primary text-capitzalize mt-2 mb-2">
          <div class="col-8">#{{ ticket.id }}: {{ ticket.subject }}</div>
          <div class="col-4 text-right py-2">
            <button
              v-if="ticket.is_open"
              class="btn btn-primary btn-sm mr-2"
              data-toggle="modal"
              data-target="#replyFormModal"
            >
              Add Reply
            </button>
            <button
              v-if="ticket.is_open"
              data-toggle="modal"
              data-target="#closeTicketModal"
              class="btn btn-warning btn-sm mr-2"
            >
              Mark as Closed
            </button>
            <span v-if="ticket.is_open" class="badge badge-pill badge-info"
              >Open</span
            >
            <span v-else class="badge badge-pill badge-success">Closed</span>
          </div>
        </div>
        <div
          v-for="message in messages"
          :key="message.id"
          class="col-md-12 mb-3"
        >
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="scroll">
                <h4 class="text-uppercase mb-3">
                  <p
                    v-if="
                      message.user.role == 'partner_staff' ||
                      message.user.role == 'partner_admin' ||
                      message.user.role == 'Manufacturer'
                    "
                    class="text-info"
                  >
                    {{ message.created | moment("dddd, MMMM Do YYYY, h:mm a") }}
                    ({{ message.user.name }})
                  </p>
                  <p
                    v-else-if="
                      message.user.role == 'ared_staff' ||
                      message.user.role == 'ared_admin'
                    "
                    class="text-success"
                  >
                    {{ message.created | moment("dddd, MMMM Do YYYY, h:mm a") }}
                    (support)
                  </p>
                </h4>
                {{ message.message }}
                <div
                  v-if="message.attachment"
                  class="d-flex justify-content-end"
                >
                  <a
                    :href="message.attachment"
                    target="_blank"
                    class="btn btn-sm btn-info"
                    >View Attachment</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="messages.length < 1" class="col-md-12 mb-3 text-center">
          No replies to ticket yet.
        </div>
        <div class="col-md-12 mb-3">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="scroll">
                <h4 class="text-uppercase mb-3">
                  <p
                    v-if="
                      ticket.user.role == 'partner_staff' ||
                      ticket.user.role == 'partner_admin' ||
                      ticket.user.role == 'Manufacturer'
                    "
                    class="text-info"
                  >
                    {{ ticket.created | moment("dddd, MMMM Do YYYY, h:mm a") }}
                    ({{ ticket.user.name }})
                  </p>
                  <p
                    v-else-if="
                      ticket.user.role == 'ared_staff' ||
                      ticket.user.role == 'ared_admin'
                    "
                    class="text-success"
                  >
                    {{ ticket.created | moment("dddd, MMMM Do YYYY, h:mm a") }}
                    (support)
                  </p>
                </h4>
                {{ ticket.message }}
                <div
                  v-if="ticket.attachment"
                  class="d-flex justify-content-end"
                >
                  <a
                    :href="ticket.attachment"
                    target="_blank"
                    class="btn btn-sm btn-info"
                    >View Attachment</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          ref="myModal"
          class="modal fade"
          id="replyFormModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="replyFormModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="replyFormModalLabel">
                  Reply to ticket
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <TicketReplyForm
                  @hide-modal="hideModal"
                  @get-replies="getReplies"
                />
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="hideModal"
                >
                  Dismiss
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="closeTicketModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="closeTicketModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="closeTicketModalLabel">
                  Close Ticket
                </h5>
              </div>
              <div class="modal-body">
                Are you sure you want to close this ticket?
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  @click="markTicketAsClosed(ticket.id)"
                  class="btn btn-primary"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import TicketReplyForm from "@/components/Tickets/ReplyForm";

export default {
  components: {
    TicketReplyForm,
  },
  data() {
    return {
      ticket: {},
      messages: {},
      adminRole: "ared_admin",
      customerSupportUserType: "Customer Support Agent",
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  mounted() {
    if (
      this.loggedInUser.role === this.adminRole ||
      this.loggedInUser.user_type === this.customerSupportUserType
    ) {
      this.getTicket(this.$route.params.id);
      this.getReplies();
    }
  },
  methods: {
    async getTicket(id) {
      try {
        axios
          .get(`v1/tickets/${id}/`)
          .then((response) => {
            if (response.status === 200) {
              this.ticket = response.data;
              console.log(`i am ${this.ticket}`);
            }
          })
          .catch((error) => {
            if (error.response) {
              this.$swal("Error", `${error.response.data.detail[0]}`, "error");
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
    async getReplies(clearReplies) {
      if (clearReplies) {
        this.messages = {};
      }
      try {
        axios
          .get(`v1/tickets/support/?ticket=${this.$route.params.id}`)
          .then((response) => {
            if (response.status === 200) {
              this.messages = response.data;
            }
          })
          .catch((error) => {
            if (error.response) {
              this.$swal("Error", `${error.response.data.detail}`, "error");
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
    markTicketAsClosed(id) {
      const data = { is_open: false };
      axios
        .patch(`v1/tickets/${id}/`, data)
        .then((response) => {
          if (response.status === 200) {
            this.$router.go();
            this.$swal("", "Ticket marked as closed", "success");
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("", "Ticket could not be marked as closed", "error");
          }
        });
    },
    hideModal() {
      document.getElementById("replyFormModal").click();
    },
  },
};
</script>

<style></style>
