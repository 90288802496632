<template>
  <form method="post" @submit.prevent="submitReply">
    <div class="form-group pt-2">
      <label for="inputEmail">Message</label>
      <textarea
        v-model="reply.message"
        class="form-control"
        id="inputMessage"
        type="text"
        rows="8"
        placeholder="Enter reply message"
        required
      />
    </div>
    <div class="form-group">
      <label for="attachmentUpload">File(optional)</label>
      <div class="input-group input-group-sm mb-3">
        <input
          type="file"
          name="file"
          accept="image/*"
          @change="onFileChange"
        />
      </div>
    </div>
    <div class="d-flex justify-content-center pt-3">
      <button class="btn btn-primary" :disabled="submitting" type="submit">
        <span v-if="submitting" class="spinner-border spinner-border-sm"></span>
        {{ submit }}
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "TicketReplyForm",
  data() {
    return {
      reply: {
        message: "",
        attachment: "",
      },
      submit: "Submit Reply",
      submitting: false,
      ticket: this.$route.params.id,
      responseErrors: "",
    };
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.reply.attachment = files[0];
    },
    createFile(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
    },
    submitReply() {
      this.submitting = true;
      this.submit = "Posting Reply...";

      const data = new FormData();
      if (this.reply.attachment !== "") {
        data.append("attachment", this.reply.attachment);
      } else {
        data.append("attachment", "");
      }
      data.append("message", this.reply.message),
        data.append("ticket", this.ticket);
      axios
        .post(`v1/tickets/support/`, data, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.status === 201) {
            this.$emit("get-replies", true);
            this.$emit("hide-modal");
            this.$swal("Sucess", "Reply Sent Successfully", "success");
          }
        })
        .catch((error) => {
          if (error.response) {
            this.responseErrors = error.response.data;
            this.submitting = false;
          }
        });
    },
  },
};
</script>

<style></style>
