var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid pb-3 pt-5 pt-md-6"},[_c('div',{staticClass:"row col-12 page-head"},[_c('nav',{attrs:{"aria-label":"breadcrumb","role":"navigation"}},[_c('ol',{staticClass:"breadcrumb page-head-nav"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'support-tickets' }}},[_vm._v("Support Tickets")])],1),_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Ticker Details")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"row col-12 text-primary text-capitzalize mt-2 mb-2"},[_c('div',{staticClass:"col-8"},[_vm._v("#"+_vm._s(_vm.ticket.id)+": "+_vm._s(_vm.ticket.subject))]),_c('div',{staticClass:"col-4 text-right py-2"},[(_vm.ticket.is_open)?_c('button',{staticClass:"btn btn-primary btn-sm mr-2",attrs:{"data-toggle":"modal","data-target":"#replyFormModal"}},[_vm._v(" Add Reply ")]):_vm._e(),(_vm.ticket.is_open)?_c('button',{staticClass:"btn btn-warning btn-sm mr-2",attrs:{"data-toggle":"modal","data-target":"#closeTicketModal"}},[_vm._v(" Mark as Closed ")]):_vm._e(),(_vm.ticket.is_open)?_c('span',{staticClass:"badge badge-pill badge-info"},[_vm._v("Open")]):_c('span',{staticClass:"badge badge-pill badge-success"},[_vm._v("Closed")])])]),_vm._l((_vm.messages),function(message){return _c('div',{key:message.id,staticClass:"col-md-12 mb-3"},[_c('div',{staticClass:"card shadow-sm"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"scroll"},[_c('h4',{staticClass:"text-uppercase mb-3"},[(
                    message.user.role == 'partner_staff' ||
                    message.user.role == 'partner_admin' ||
                    message.user.role == 'Manufacturer'
                  )?_c('p',{staticClass:"text-info"},[_vm._v(" "+_vm._s(_vm._f("moment")(message.created,"dddd, MMMM Do YYYY, h:mm a"))+" ("+_vm._s(message.user.name)+") ")]):(
                    message.user.role == 'ared_staff' ||
                    message.user.role == 'ared_admin'
                  )?_c('p',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm._f("moment")(message.created,"dddd, MMMM Do YYYY, h:mm a"))+" (support) ")]):_vm._e()]),_vm._v(" "+_vm._s(message.message)+" "),(message.attachment)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('a',{staticClass:"btn btn-sm btn-info",attrs:{"href":message.attachment,"target":"_blank"}},[_vm._v("View Attachment")])]):_vm._e()])])])])}),(_vm.messages.length < 1)?_c('div',{staticClass:"col-md-12 mb-3 text-center"},[_vm._v(" No replies to ticket yet. ")]):_vm._e(),_c('div',{staticClass:"col-md-12 mb-3"},[_c('div',{staticClass:"card shadow-sm"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"scroll"},[_c('h4',{staticClass:"text-uppercase mb-3"},[(
                    _vm.ticket.user.role == 'partner_staff' ||
                    _vm.ticket.user.role == 'partner_admin' ||
                    _vm.ticket.user.role == 'Manufacturer'
                  )?_c('p',{staticClass:"text-info"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.ticket.created,"dddd, MMMM Do YYYY, h:mm a"))+" ("+_vm._s(_vm.ticket.user.name)+") ")]):(
                    _vm.ticket.user.role == 'ared_staff' ||
                    _vm.ticket.user.role == 'ared_admin'
                  )?_c('p',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.ticket.created,"dddd, MMMM Do YYYY, h:mm a"))+" (support) ")]):_vm._e()]),_vm._v(" "+_vm._s(_vm.ticket.message)+" "),(_vm.ticket.attachment)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('a',{staticClass:"btn btn-sm btn-info",attrs:{"href":_vm.ticket.attachment,"target":"_blank"}},[_vm._v("View Attachment")])]):_vm._e()])])])]),_c('div',{ref:"myModal",staticClass:"modal fade",attrs:{"id":"replyFormModal","tabindex":"-1","role":"dialog","aria-labelledby":"replyFormModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[_c('TicketReplyForm',{on:{"hide-modal":_vm.hideModal,"get-replies":_vm.getReplies}})],1),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":_vm.hideModal}},[_vm._v(" Dismiss ")])])])])]),_c('div',{staticClass:"modal fade",attrs:{"id":"closeTicketModal","tabindex":"-1","role":"dialog","aria-labelledby":"closeTicketModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(1),_c('div',{staticClass:"modal-body"},[_vm._v(" Are you sure you want to close this ticket? ")]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.markTicketAsClosed(_vm.ticket.id)}}},[_vm._v(" Confirm ")])])])])])],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"replyFormModalLabel"}},[_vm._v(" Reply to ticket ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"closeTicketModalLabel"}},[_vm._v(" Close Ticket ")])])
}]

export { render, staticRenderFns }